import styled from 'styled-components'

const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  background-color: #5f6260;
 
  .footer-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 4vh 0;
  }
 
  .footer-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    
    div {
      padding: 4vh 0;
    }
    
    .link {
      text-decoration: inherit;
      color: inherit;
    }
  }
`

export default FooterStyles;