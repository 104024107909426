import React from "react"
import FooterImageGridStyles from "./FooterImageGridStyles"

function FooterImageGrid({children, ...rest}) {
  return (
    <FooterImageGridStyles>
      {children}
    </FooterImageGridStyles>
  )
}

export default FooterImageGrid;