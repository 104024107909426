import React from "react"
import FooterBlockStyles from "./FooterBlockStyles"
import StyledFooterBackgroundImage from "../FooterBackgroundImage/FooterBackgroundImage"
import { Link } from 'gatsby'

const FooterBlock = ({imgName, altText, titleText, detailText, textColor, linkDest, ...rest}) => {
  return (
    <FooterBlockStyles textColor={textColor}>
      <Link className={'link'} to={linkDest||''}>
        <StyledFooterBackgroundImage imgName={imgName}>
          <div className={'footer-image-gradient'}>
            <div className={'footer-image-title'}>{titleText}</div>
            <div className={'footer-image-detail'}>{detailText}</div>
          </div>
        </StyledFooterBackgroundImage>
      </Link>
    </FooterBlockStyles>
  )
}

export default FooterBlock