import React from 'react'
import HeaderStyles from './HeaderStyles'
import { Link } from 'gatsby'

export default () => {
  return (
    <HeaderStyles>
      <div className={'blur'}/>
      <nav className={'header'}>
        <Link className={'link'} to="/">
          <div className={'header-item'}>Home</div>
        </Link>
        <Link className={'link'} to="/blog">
          <div className={'header-item'}>Blog</div>
        </Link>
        <Link className={'link'} to="/projects">
          <div className={'header-item'}>Projects</div>
        </Link>
        <Link className={'link'} to="/about">
          <div className={'header-item'}>About</div>
        </Link>
      </nav>
    </HeaderStyles>
  );
}
