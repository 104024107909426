import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from "react-helmet"

export default ({children, ...rest}) => {
  return(
    <div>
      <Helmet htmlAttributes={{ lang: 'en' }} >
        <meta charSet="utf-8" />
        <meta name={'description'} content={'Alan Henson\'s personal site'}
        />
        <title>Alan Henson</title>
      </Helmet>
      <Header/>
      <main>{children}</main>
      <Footer/>
    </div>
  );
}
