import styled from "styled-components"

const LayoutStyles = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .above-fold-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gradient-billboard {
    padding: 15vh 0;
  }

  .gradient-billboard-detail {
    height: 100vh;
    background: linear-gradient(#000 81.87%, #bfbaad 100%);
    padding: 0 5vw 0 5vw;
  }

  .mat-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 7vh 0 0 0;
  }

  .typography-tech {
    font-family: "Fira Code", monospace;
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
  }

  .typography-main {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 9vh;
  }

  .typography-intro-detail {
    font-size: 1.2rem;
    color: #cbc6ba;
    margin: 0 0 10vh 0;
    line-height: 1.9rem;

    @media only screen and (min-width: 769px) {
      font-size: 1.5rem;
    }
  }

  .typography-link {
    text-decoration: underline;
  }
  
  .typography-link-tan {
    background-color: #BFBAAD;
    color: #000;
  }
  
  .typography-link-gray {
    background-color: #FFF;
    color: #000;
  }
  
  .scroll-nudge {
    position: absolute;
    bottom: 0;
    color: #cbc6ba;
    text-align: center;
    font-size: 2rem;
    width: 100vw;
  }

  .unstyled-link {
    text-decoration: none;
    color: inherit;
  }

  .text-list {
    width: 70%;
    padding: 4vh 0;
    clear: both;
    color: #fff;
    font-size: 1.2rem;

    @media only screen and (max-width: 425px) {
      width: 100%;
    }

    @media only screen and (min-width: 426px) {
      width: 100%;
    }

    @media only screen and (min-width: 769px) {
      width: 70%;
      font-size: 1.5rem;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 6vh 4vw;

      li {
        margin: 4vh 0;
      }
    }
  }

  .text-list-right {
    @media only screen and (min-width: 769px) {
      float: right;
    }
  }
`

export default LayoutStyles
