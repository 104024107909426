import React, { useEffect, useState } from "react"

const ScrollNudge = ({ color }) => {

  const [nudgeOpacity, setNudgeOpacity] = useState(1);

  const handleScroll = () => {
    setNudgeOpacity(Math.min(1-((window.pageYOffset*3)/window.innerHeight),1));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={'scroll-nudge'} style={{opacity: `${nudgeOpacity}`, color: `${color || '#cbc6ba'}`}}>
      &#709;
    </div>
  )
}

export default ScrollNudge