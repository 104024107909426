import styled from "styled-components"

const HeaderStyles = styled.header`
  
  .blur {
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    height: 10vh;
    width: 100vw;
    min-width: 320px;
    position: absolute;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(196,196,196, 1) 0%,
      rgba(252, 252, 250, 0) 100%
    )
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    min-width: 320px;
    height: 10vh;
    padding-bottom: 1vh;
    position: absolute;
    top: 0;
 
    .link {
      text-decoration: inherit;
      color: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .header-item {
        margin: 1vh 0;
        width: 23vw;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        font-family: 'Fira Code', Arial, sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
         
        @media only screen and (min-width: 768px) {
          font-size: 1.5rem;
        }
      }  
 
      .header-item:hover {
        text-decoration: underline;
        cursor: pointer;
        //background: rgba(255,255,255,0.2);
        background: linear-gradient(
          to right,
          rgba(255,255,255,0.0) 0%,
          rgba(255,255,255,0.8) 50%,
          rgba(255,255,255,0.0) 100%
        )
      }
    }
  }
`

export default HeaderStyles;