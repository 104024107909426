import styled from "styled-components"

const FooterBlockStyles = styled.div`
  height: 100%;
  width: 50vw;

  @media (max-width: 475px) {
    width: 100vw;
  }
  
  .link {
    text-decoration: inherit;
    color: inherit;
  }

  .footer-image-gradient {
    background: linear-gradient(
      rgba(95, 98, 96, 1) 0%,
      rgba(95, 98, 96, 0) 25%,
      rgba(95, 98, 96, 0) 75%,
      rgba(95, 98, 96, 1) 100%
    );
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 95vh;
    justify-content: space-between;

    .footer-image-title {
      color: ${props => props.textColor};
      padding: 5vh 2vw;
      font-size: 2.1rem;
      font-weight: 400;
    }

    .footer-image-detail {
      color: ${props => props.textColor};
      padding: 5vh 2vw;
      font-size: 1.7rem;
      font-weight: 400;
    }
  }
`

export default FooterBlockStyles;