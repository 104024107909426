import React from 'react'
import FooterStyles from "./FooterStyles"
import FooterBlock from "../FooterBlock/FooterBlock"
import FooterImageGrid from "../FooterImageGrid/FooterImageGrid"

export default () => {
  return (
      <FooterStyles>
        <FooterImageGrid>
          <FooterBlock imgName={'smallPlanet'} titleText={'Home'} detailText={'Welcome to My World'} textColor={'#BFBAAD'} linkDest={'/'}/>
          <FooterBlock imgName={'buildingsAndStreet'} titleText={'Blog'} detailText={'If Readers are Leaders, then Writers are... ?'} textColor={'#0C1C13'} linkDest={'/blog'}/>
          <FooterBlock imgName={'cliffAndPond'} titleText={'Projects'} detailText={'My Code-tributions to the planet'} textColor={'#0C1C13'} linkDest={'/projects'}/>
          <FooterBlock imgName={'jellyfish'} titleText={'About'} detailText={"Let's get acquainted."} textColor={'#BFBAAD'} linkDest={'/about'}/>
        </FooterImageGrid>
        <div>All Media & Content &copy; 2020 Alan Henson</div>
      </FooterStyles>
  );
}
