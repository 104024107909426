import React from "react"
import { graphql, StaticQuery} from "gatsby"
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const FooterBackgroundImage = ({ children, className, imgName}) => (
  <StaticQuery
    query={graphql`
      query {
        buildingsAndStreet: file(relativePath: { eq: "buildings-street.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        jellyfish: file(relativePath: { eq: "jellyfish.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        cliffAndPond: file(relativePath: { eq: "cliff-pond.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        smallPlanet: file(relativePath: { eq: "small-world-lookout.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
  `}

    render={data => {
      //Set Image Data.
      let imageData = null;
      switch (imgName) {
        case 'buildingsAndStreet':
          imageData = data.buildingsAndStreet.childImageSharp.fluid
          break;
        case 'jellyfish':
          imageData = data.jellyfish.childImageSharp.fluid
          break;
        case 'cliffAndPond':
          imageData = data.cliffAndPond.childImageSharp.fluid
          break;
        case 'smallPlanet':
          imageData = data.smallPlanet.childImageSharp.fluid
          break;
        default:
          console.log(`Hit default case in image data, imageName received was ${imgName}`)
          break;
      }

      return (
        <BackgroundImage
          Tag={'div'}
          className={className}
          fluid={imageData}
          backgroundColor={`#BFBAAD`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const StyledFooterBackgroundImage = styled(FooterBackgroundImage)`
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50vw;
  
  @media (max-width: 475px) {
    width: 100vw;
  }
`

export default  StyledFooterBackgroundImage
