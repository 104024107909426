import styled, { css } from "styled-components"

const SectionTransition = styled.div`
  position: absolute;
  width: 100%;
  height: 10vh;
  background: linear-gradient(
    ${props => props.from || 'rgba(0, 0, 0, 0) 0%'},
    ${props => props.to || '#000'}
  );
  
  ${props => props.static && css`
    position: static;
  `}
`

export default SectionTransition
