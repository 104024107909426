import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundImageFull = ({ children, className, imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        mountainCity: file(relativePath: { eq: "mountain-city.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        buildingsAndStreet: file(relativePath: { eq: "buildings-street.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        jellyfish: file(relativePath: { eq: "jellyfish.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        cliffAndPond: file(relativePath: { eq: "cliff-pond.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        smallPlanet: file(relativePath: { eq: "small-world-lookout.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        fireTower: file(relativePath: { eq: "firetower.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        sunraysTrees: file(relativePath: { eq: "sunrays-trees.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        cakeSprinkles: file(relativePath: { eq: "cake-sprinkles.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        dale1: file(relativePath: { eq: "dale-statue-building-sky.jpg"}) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },,
      }
  `}

    render={data => {
      //Set Image Data.
      let imageData = null;
      switch (imgName) {
        case 'mountainCity':
          imageData = data.mountainCity.childImageSharp.fluid
          break;
        case 'buildingsAndStreet':
          imageData = data.buildingsAndStreet.childImageSharp.fluid
          break;
        case 'jellyfish':
          imageData = data.jellyfish.childImageSharp.fluid
          break;
        case 'cliffAndPond':
          imageData = data.cliffAndPond.childImageSharp.fluid
          break;
        case 'smallPlanet':
          imageData = data.smallPlanet.childImageSharp.fluid
          break;
        case 'fireTower':
          imageData = data.fireTower.childImageSharp.fluid
          break;
        case 'sunraysTrees':
          imageData = data.sunraysTrees.childImageSharp.fluid
          break;
        case 'cakeSprinkles':
          imageData = data.cakeSprinkles.childImageSharp.fluid
          break;
        case 'dale1':
          imageData = data.dale1.childImageSharp.fluid
          break;
        default:
          console.log(`Hit default case in image data, imageName received was ${imgName}`)
          break;
      }
      return (
        <BackgroundImage
          Tag={'div'}
          className={className}
          fluid={imageData}
          backgroundColor={`#BFBAAD`}
          style={{position:'absolute'}}
        >
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundImageFull = styled(BackgroundImageFull)`
  z-index: -1;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`
export default StyledBackgroundImageFull
